/* src/App.css */
.App {
  font-family: Arial, sans-serif;
  padding: 20px;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 10px;
}

label {
  display: block;
  margin: 10px 0 5px;
}

input {
  margin-bottom: 10px;
  padding: 5px;
}

button {
  display: inline-block;
  margin: 5px 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

tbody tr:hover {
  background-color: #f1f1f1;
}

input[type="text"],
input[type="number"] {
  width: 100%;
  box-sizing: border-box;
}

tfoot td {
  font-weight: bold;
}